import tinycolor from "tinycolor2";

export const Notification = ({ primaryColor }) => {
  const lighterPrimaryColor = tinycolor(primaryColor).brighten(85).toString();
  const cardStyle = {
    backgroundColor: lighterPrimaryColor,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: primaryColor,
  };

  const hideNotification = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById("notification").style.top = "-100pt";
  };

  return (
    <div
      id="notification"
      className="notification"
      style={cardStyle}
      onClick={hideNotification}
    >
      <span id="notification_text" className="notification_text"></span>
    </div>
  );
};

export const showNotification = (text, time = 2500) => {
  document.getElementById("notification_text").innerHTML = text;
  document.getElementById("notification").style.top = "15pt";
  setTimeout(function () {
    document.getElementById("notification").style.top = "-100pt";
  }, time);
};
